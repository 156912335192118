exports.components = {
  "component---cache-caches-gatsby-plugin-offline-next-app-shell-js": () => import("./../../caches/gatsby-plugin-offline-next/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-next-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cases-js": () => import("./../../../src/pages/cases.js" /* webpackChunkName: "component---src-pages-cases-js" */),
  "component---src-pages-cases-research-js": () => import("./../../../src/pages/cases/research.js" /* webpackChunkName: "component---src-pages-cases-research-js" */),
  "component---src-pages-cases-restock-js": () => import("./../../../src/pages/cases/restock.js" /* webpackChunkName: "component---src-pages-cases-restock-js" */),
  "component---src-pages-cases-smart-match-js": () => import("./../../../src/pages/cases/smart-match.js" /* webpackChunkName: "component---src-pages-cases-smart-match-js" */),
  "component---src-pages-cases-vistabit-js": () => import("./../../../src/pages/cases/vistabit.js" /* webpackChunkName: "component---src-pages-cases-vistabit-js" */),
  "component---src-pages-consumer-research-js": () => import("./../../../src/pages/consumer-research.js" /* webpackChunkName: "component---src-pages-consumer-research-js" */),
  "component---src-pages-contact-thank-you-js": () => import("./../../../src/pages/contact/thank-you.js" /* webpackChunkName: "component---src-pages-contact-thank-you-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-fintech-js": () => import("./../../../src/pages/industry/fintech.js" /* webpackChunkName: "component---src-pages-industry-fintech-js" */),
  "component---src-pages-product-design-js": () => import("./../../../src/pages/product-design.js" /* webpackChunkName: "component---src-pages-product-design-js" */),
  "component---src-pages-product-strategy-js": () => import("./../../../src/pages/product-strategy.js" /* webpackChunkName: "component---src-pages-product-strategy-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-services-ai-ml-solution-js": () => import("./../../../src/pages/services/ai-ml-solution.js" /* webpackChunkName: "component---src-pages-services-ai-ml-solution-js" */),
  "component---src-pages-services-devops-and-aws-optimization-js": () => import("./../../../src/pages/services/devops-and-aws-optimization.js" /* webpackChunkName: "component---src-pages-services-devops-and-aws-optimization-js" */),
  "component---src-pages-services-fintech-saas-development-js": () => import("./../../../src/pages/services/fintech-saas-development.js" /* webpackChunkName: "component---src-pages-services-fintech-saas-development-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-ux-ui-services-js": () => import("./../../../src/pages/services/ux-ui-services.js" /* webpackChunkName: "component---src-pages-services-ux-ui-services-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-web-development-js": () => import("./../../../src/pages/web-development.js" /* webpackChunkName: "component---src-pages-web-development-js" */),
  "component---src-templates-ai-factory-case-template-js": () => import("./../../../src/templates/aiFactoryCaseTemplate.js" /* webpackChunkName: "component---src-templates-ai-factory-case-template-js" */),
  "component---src-templates-ai-factory-template-js": () => import("./../../../src/templates/aiFactoryTemplate.js" /* webpackChunkName: "component---src-templates-ai-factory-template-js" */),
  "component---src-templates-careers-role-template-js": () => import("./../../../src/templates/careersRoleTemplate.js" /* webpackChunkName: "component---src-templates-careers-role-template-js" */),
  "component---src-templates-careers-template-js": () => import("./../../../src/templates/careersTemplate.js" /* webpackChunkName: "component---src-templates-careers-template-js" */),
  "component---src-templates-case-studies-template-js": () => import("./../../../src/templates/caseStudiesTemplate.js" /* webpackChunkName: "component---src-templates-case-studies-template-js" */),
  "component---src-templates-case-study-template-js": () => import("./../../../src/templates/caseStudyTemplate.js" /* webpackChunkName: "component---src-templates-case-study-template-js" */),
  "component---src-templates-contact-us-template-js": () => import("./../../../src/templates/contactUsTemplate.js" /* webpackChunkName: "component---src-templates-contact-us-template-js" */),
  "component---src-templates-podcast-list-template-js": () => import("./../../../src/templates/podcastListTemplate.js" /* webpackChunkName: "component---src-templates-podcast-list-template-js" */),
  "component---src-templates-podcast-template-js": () => import("./../../../src/templates/podcastTemplate.js" /* webpackChunkName: "component---src-templates-podcast-template-js" */)
}

