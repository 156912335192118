const LogRocket = require('logrocket')
exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
                              }) => {
  // const { pathname } = location
  // list of routes for the scroll-to-top-hook
  // const scrollToTopRoutes = [`/privacy-policy`, `/page-2`]
  // if the new route is part of the list above, scroll to top (0, 0)
  // if (scrollToTopRoutes.indexOf(pathname) !== -1) {
  const { pathname, hash } = location;
  setTimeout(() => {
  if (hash) {
    // Scroll to the section if there's a hash
    const element = document.querySelector(hash);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      return false; // Prevent default scroll
    }
  }
  // Default scroll to top
  window.scrollTo(0, 0);
}, 100);
  // }

  return false
}

exports.onClientEntry = () => {
  LogRocket.init('iqwzgp/productera');
}
